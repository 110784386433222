 
import { useEffect, useState } from "react";
import { useLocation, } from 'react-router-dom';

import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";

import axios from "axios";

export default function Gallery() {

   
    const location = useLocation();
    //console.log("gallery location");
 //   console.log(location);

    const[page, setPage] = useState(1);
    const[maxPages,setMaxPages] = useState(0);
    const[maxId,setMinId] = useState(0);
    const[offset,setOffset] = useState(0);
    const[count,setCount] = useState(0);
    const[entries,setEntries] = useState([]);

    function handlePageUpdate(newpage){

        setOffset((newpage-1)*99);
        setPage(newpage)
        console.log(newpage+" "+ offset);

    }
    useEffect(()=>{
        if (location.state != null){
            handlePageUpdate(location.state.page);
        }
    },[]);

    useEffect(()=>{
        axios.get("/api/v1/stats/simulationcount/").then((response)=>{
            setCount(response.data);
            setMaxPages(response.data/99)
        });
    },[]);

    useEffect(()=>{
        let params = {
            after_id:maxId,
            offset:offset,
        }
        axios.post("/api/v1/gallery/",params).then((response)=>{
            setEntries(response.data.entries);
        });


    },[page]);


    function getPages(current){
        const pages = [];

        for(let i = current-2;i<current+5;i++){
     //       console.log(i)
            if(i>0){
                pages.push(i);
            }
            if(pages.length===5){
                break;
            }
        }
        return pages;
    }
return (<>
 <div className="gallery">
    {[...entries].map(x=><Preview id={x.simulation_id} src={`/${x.image_url}`}  to={{ pathname: "/viewer", state:{page}}}/>)}
 </div>
 <div style={{paddingBottom:"5em"}}></div>
 <div className="controls centered"> 
    <Button onClick={()=>handlePageUpdate(page-1)} disabled={page===1}>&lt;</Button>
    {
        [...getPages(page)].map((x)=><Button onClick={()=>handlePageUpdate(x)}>{x===page?<u><b>{x}</b></u>:x}</Button>)
    }
    <Button onClick={()=>handlePageUpdate(page+1)} disabled={page===maxPages}>&gt;</Button>
    </div>
</>);
}

/*
 <div className="controls"> 
          <Button variant="secondary" className="filterbutton">Filters           <span className="notification-badge">0</span></Button>

        </div>*/
function Preview({id, src,to}){
    const pagenum = to.state.page;
  //  console.log({to});
    return (
        <>
        <Button 
        as={Link}
        to={to}
        state={{simu_id:{id},page:pagenum}}
        className="nostylebtn thumb-preview">
            <img width="100%" height="100%" src={src}/>
        </Button>

        </>
    );
}