
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useState, 
  useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SimulationStats from "../components/SimulationStats";
import ImageView from "../components/ImageView";
import { useLocation,useNavigate } from 'react-router-dom';

import axios from "axios";

function Viewer({initial_id}) {
  const location = useLocation();
 // console.log(location);
  const navigate = useNavigate();          // React Router's navigation hook

const [viewerPage,setViewerPage] = useState(1);

  const [simulation_id, updateId] = useState(location.state==null?initial_id ? initial_id:1:location.state.simu_id.id)

  const [simulationData, updateSimulation] = useState({rules:""})
  const [highwayData, updateHighway] = useState(null)
  const [images, updateImages] = useState(null)

  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    console.log("setting viewer page..");
    if(location.state!= null){
      setViewerPage(location.state.page);
    }
  },[]);

  useEffect(() => {
    axios.get('/api/v1/simulation/'+simulation_id).then((response)=>{
      handleIdUpdate(response.data.simulation.id);
      updateHighway(response.data.highway_data);
      updateSimulation(response.data.simulation);
      setLoading(false);
      navigate(`/viewer/${response.data.simulation.rules}`, { replace: true });
    });
    axios.get('/api/v1/images/'+simulation_id).then((response)=>{
      updateImages(response.data.images);
    });
  },[simulation_id]);


  function getMainImage(){
    if(images===null)
      return "";
    for(let image of images){
      if(image.scope==="IMAGE" && image.palette==="REGULAR"){
    
        return `/${image.url}`;
      }
    }
  }

  function getHeatImage(){
    if(images===null)
      return "";
    for(let image of images){
      if(image.scope==="IMAGE" && image.palette==="HEATMAP"){
    
        return `/${image.url}`;
      }
    }
  }

function nextId(){
  updateId(simulation_id+1);
}

function previousId(){
  updateId(simulation_id-1);
}

function handleIdUpdate(id){
if(id !== simulation_id){
  updateId(id);
}
}
if (loading) {
  return <div>Loading...</div>; // Or any loading spinner/placeholder
}


  return (
    <div className="App">
          <div className="rule">
            <span className="rule-text">{simulationData.rules}</span>
            </div>

        <div className="container">
          <div className="large-image-view shadow-border">
            <ImageView src={getMainImage()}/>
          </div>

          <div className="right-column">
            <div className="smaller-view shadow-border">
              <SimulationStats highwayData={highwayData} simulationData={simulationData}/>
            </div>
            <div className="smaller-view shadow-border ">
              <ImageView src={getHeatImage()}/>
            </div>
          </div>
        </div>
        <div className="controls"> 
          <Button onClick={()=>previousId()}>Previous</Button>
        
          <Button 
          variant="outline-info" 
          as={Link} 
          to={"/gallery"} 
          state={{page:viewerPage}}
          onClick={()=>previousId()}>Open gallery</Button>
          <Button onClick={()=>nextId()}>Next</Button>
        </div>
    </div>
  );
}

export default Viewer;
