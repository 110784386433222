

import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
export default function Navigation() {
    return (<>
    <div className="front-nav">
    <Card name={"Info"} bgImage={"/infobg.png"} to={"/info"}/>
    <Card name={"Viewer"} bgImage={"/viewerbg.png"} to={"/viewer"} />
    <Card name={"Gallery"} bgImage={"/gallerybg.png"} to={"/gallery"}/>

    </div>
    </>);
    }

/*    <Card name={"Exports"} bgImage={"/placehold.jpg"} to={"/info"}/>
    <Card name={"Stats"} bgImage={"/placehold3.jpg"} to={"/viewer"} />
    <Card name={"Gallery"} bgImage={"/placehold2.jpg"} to={"/gallery"}/>*/
function Card({name, bgImage, to}){
    return(

        <Button as={Link} to={to} className="page-card" style={{  backgroundSize:"100%",backgroundImage: `url(${bgImage}`} }>

        <h2 className="page-card-title">{name}</h2>
        </Button>
    );
}