import { Link, Outlet, useLocation } from "react-router-dom";
import Navigation from "./nav";

export default function Root() {
    let location = useLocation();

    //console.log(location);

    
return (<>
 <h1 className="centered">Langtons Ant</h1>
 {location.pathname=="/"?<Navigation/>:<><Outlet/><Link to={"/"}>back</Link></>}
</>);
}