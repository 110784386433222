import TableRow from "./InfoTable";
function SimulationStats({simulationData, highwayData}){
   // console.log("simudata:"+simulationData);
   // console.log("hwdata"+highwayData)

    if(simulationData === null){
        return(<></>);
    }
    return(
    <>
    <div className="simulation-stats">
        <h2 className="subtitle">
            General info
        </h2>
    <div className="table-view">
        <TableRow left="Iterations:" right={format(simulationData.iterations)}/>
        <TableRow left="Visited cells:" right={format(simulationData.total_visited)}/>
        <TableRow left="Rule length:" right={simulationData.rule_length}/>
        <TableRow left="Rule as number:" right={Number(simulationData.rule_int)}/>
    </div>

    <h2 className="subtitle">Highway info</h2>
        {highwayData?
    <div className="table-view">
    <TableRow left="Pattern start:" right={format(highwayData.patternbegin)}/>
    <TableRow left="Pattern length:" right={format(highwayData.pattern_length)}/>
    <TableRow left="Pattern class:" right={classMap[highwayData.search_result][0]} sup={classMap[highwayData.search_result][1]}/>
    <TableRow left="Direction:" right={formatDegrees(highwayData.degrees)}/>
    <TableRow left="&Delta;x:" right={format(highwayData.dx)}/>
    <TableRow left="&Delta;y:" right={format(highwayData.dy)}/>
    <TableRow left="Speed:" right={highwayData.genpersquare.toFixed(2)+" iterations/square"}/>
  </div>:
     <TableRow left="No highway found." right=""/>
  }  </div>
  </>);

}

const classMap={
"NOT_FOUND": ["No highway found.","No highway was detected with the search parameters"],
"FOUND": ["Infinite","The highway appears to continue infinitely many repeations."],
"UNVERIFIED_FOUND": ["Not verified","Highway was found, but infinite repeats were not tested"],
"NON_PERMANENT_INEXACT": ["Partial (random)","Highway was found, but it's not quaranteed a similar did not start earlier due to the detection mechanism only looking at the latest moves."],
"NON_PERMANENT_EXACT": ["Partial (Exact)","Highway does not continue infinitely and this is the exact location where it first repeats."]

}

function formatDegrees(x){
    return `${x.toFixed(2)}`+'\u00B0';
}


function format(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export default SimulationStats;