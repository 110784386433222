function TableRow({left,right,sup}){
return (
    <>
    <div className="table-row">
       <TableCell alignment="left-align" value={left}/>
       <TableCell alignment="right-align" value={right} sup={sup}/>
    </div>
    </>
);
}

function TableCell({alignment, value, sup}){
  return(
    <>
    <div className={"table-cell "+alignment}>{value}{sup?<sup><span title={sup}>[?]</span></sup>:""}</div>
    </>
  );
}

export default TableRow;