import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Viewer from './routes/viewer';

import "./main.css";
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from "./routes/root";
import Info from "./routes/info";
import Gallery from "./routes/gallery";
import Navigation from './routes/nav';

//https://reactrouter.com/en/main/start/tutorial
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    children: [
      {
        path: "/info",
        element: <Info/>
        },
      {
      path: "/gallery",
      element: <Gallery/>
      }
    ],

  },

  {
    path: "/viewer/:rules?",
    element: <Viewer />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
