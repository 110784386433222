 
import Markdown from 'react-markdown'
import { Link } from 'react-router-dom';
import rehypeRaw from "rehype-raw";
export default function Info() {

const content = `

<div class=centered>
<video style="max-width:100%" autoplay muted>
  <source src="/infoimages/ant.webm" type="video/webm">
</video>
</div>

Langton's ant is a 2D Turing machine. The ant moves on an infinite grid, following certain rules.

- Frist, read the cell color under the ant. 
- If the cell is black.. 
   1. Turn 90 degrees <i>counter-clockwise</i> ("Left"), 
   2. Change the color of the cell to white 
   3. Move one cell forward to the direction it is currently facing.
- If the cell is white.. 
   1. Trun 90 degrees <i>clockwise</i> ("Right"), 
   2. Change the color of the cell to white 
   3. Move one cell forward to the direction it is currently facing.

Given an infinite grid of black cells, the ant follows a specific pattern.

For the first 400 moves it makes a rather symmetric pattern:
<div class=centered>

![](/infoimages/image-400.png)

</div>
After the initial patterns, until the move 9978 it moves chaotically.

![](/infoimages/image-9978.png)


Finally at this point, something curious happens. The ant gets stuck, making a repeating pattern that is 104 moves long.

![](/infoimages/image-11530.png)

This ant never does anything else again: it just extends the pattern infinitely.

This is called a _highway_.

## Changing the rules

The standard ant turns left on black cells and right on white cells. This can be 
expressed as LR. These rules are read from left to right, each representing the direction where the Ant turns in a given state.

But what if instead of black and white, we also have <span style="color:rgb(76, 187, 23)">green</span> cells? This would mean that the cell colors now change from black&rarr;white&rarr;green&rarr;black. Now we need another rule. We could for example use the rule: LRL. Therefore:

- black &rarr; left &rarr; turn the cell white
- white &rarr;right &rarr; turn the cell green
- green &rarr;left &rarr; turn the cell black

Now the ant makes a completely different pattern.

![](/infoimages/LRLimage-11530.png)


If we change the rules to LRR, the pattern changes again, though it's not significantly more interesting:

![](/infoimages/LRRimage-11530.png)

All these three rules were simulated for 11530 moves.

In general, we can define any number of consecutive states and assign a rule for the ant to turn left/left. For a more stunning example, the next image is rule LRRRRRLRRRRLLL, simulated for 697263 moves. This creates a pattern that is 7006 moves long, extending the overlapping rectangular structures infinitely towards the upper right corner:

![](/infoimages/LRRRRRLRRRRLLLimage-697263.png)

This page serves as a huge repository of tested rules and rendered images. 

**Some notes about rules:**

LL or RR would be a useless pattern, because the ant would just spin in a 2x2 square.

LR is the same as RL, but the image is mirrored.

LRLR or LRLRLR is same as LR, just with more colors. This applies to any repeating rule. The colors don't affect the movement of the ant, only the turning rules. L&rarr;R&rarr;...&rarr;L&rarr;R or any repeating pattern is always the same cyclical movement. Testing these would be a waste of time.

# Highways in all forms

The trivial ant reaches a highway after 9978 moves and the pattern length is 104 moves.

Some questions to ask:

* Do other rules cause the ant to create a highway? (yes)
* Are there longer/shorter highways? (yes).


`;

const extensions=`
# Notable extensions

Here are some common variants. While these are interesting, so far this page contains only the regular ants.

1. More moves: N and U. This simple extension adds more turning options for the ant. N means no turn, just move forward, and U means u-turn, ie. 180 degree turn.
2. Hexagonal grid. In such grid the ant can turn any 60 degree increment. [Some examples](https://en.wikipedia.org/wiki/Langton%27s_ant) are available on wikipedia.

3. [_Turmite_](https://en.wikipedia.org/wiki/Turmite) is a variation that has an internal state that can change depending on the color of the cell. The internal state can also change which color is written to the cell, or which direction to turn to. This is effectively a 2D version of the regular Turing machine. Langton's Ant can be considered a special case of a Turmite: the internal state is 0 and never changes to anything else.

`;

return (<><div className='text'>
 <Markdown rehypePlugins={[rehypeRaw]} >{content}</Markdown>
<Link to= {"/gallery"}>
<span>Click here to view the Gallery</span></Link>
 <Markdown>{extensions}</Markdown>
 </div>
</>);
}